import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import logo from "../images/logo.svg"
import axios from 'axios'

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.data = props.data

        this.state = {
            form_address: '',
            formatted_address: '',
            lat: '',
            lng: '',
            district_name: '',
            district_geo: '',
            formError: [],
            formSuccess: '',
        }
    }

    submit = (event) => {
        event.preventDefault()
        this.setState( { formSuccess: '', formError: [] }, this.handleFormEntry )
    }

    handleFormEntry = () => {
        if( this.state.form_address  === '' ) {
            this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
        } else {

            axios.get('https://geo.tsds.info/api/get-district?address='+encodeURI(this.state.form_address)+'&districts=%5B%7B%22name%22%3A%22Grand%20Lakes%20MUD%201%22%7D%2C%7B%22name%22%3A%22Grand%20Lakes%20MUD%202%22%7D%2C%7B%22name%22%3A%22Grand%20Lakes%20MUD%204%22%7D%5D').then( function(response) {
                console.log('Response');
                console.log(response.data);
                this.clearForm();
                this.setState({ 
                    formSuccess: "Found the following address",
                    formatted_address: response.data.formatted_address,
                    lat: response.data.lat,
                    lng: response.data.lng,
                    district_name: response.data.district_name,
                    district_geo: response.data.district_geo,
                });

            }.bind(this) ).catch( function(error) {
                console.log(error.response)
            })

        }
    }

    clearForm = () => {
        this.setState({ form_address: '', formError: [] });
    }
    reset = () => {
        this.setState({ form_address: '', formError: [], formSuccess:'', formatted_address: '', lat: '', lng: '', district_geo: '', district_name: '' });
    }
    changeAddress = (event) => {
        this.setState({ form_address: event.target.value })
    }


    render() {
        return (
            <Layout>
                <SEO title="Select your District" />
                <div className="root-background">
                    <Img className="item1" fluid={this.data.image1.childImageSharp.fluid} alt="" />
                    <Img className="item2" fluid={this.data.image2.childImageSharp.fluid} alt="" />
                    <div className="item3 filled"></div>
                    <Img className="item4" fluid={this.data.image3.childImageSharp.fluid} alt="" />
                    <Img className="item5" fluid={this.data.image4.childImageSharp.fluid} alt="" />
                    <div className="item6 filled"></div>
                    <Img className="item7" fluid={this.data.image5.childImageSharp.fluid} alt="" />
                    <Img className="item8" fluid={this.data.image6.childImageSharp.fluid} alt="" />
                </div>
                <div className="root-background-overlay">
                </div>
                <div className="center-window">
                    <div className="window-body">
                        <img className="root-logo" src={logo} alt="" />
                        <div className="">
                            <div className="has-text-centered"><strong>Select your district to enter:</strong></div>
                            <a href="https://www.grandlakesmud1.com/" className="button">Grand Lakes MUD 1</a>&nbsp;
                            <a href="https://www.grandlakesmud2.com/" className="button">Grand Lakes MUD 2</a>&nbsp;
                            <a href="https://www.grandlakesmud4.com/" className="button">Grand Lakes MUD 4</a>&nbsp;
                        </div>



                        { this.state.formSuccess === '' ? ( 
                            <>
                            <h4 className="has-text-primary has-text-centered" style={{fontWeight: 600}}>OR</h4>
                            <form className="form" onSubmit={this.submit}>
                                <div className="field">
                                    <label className="label">Enter your address to search for your district:</label>
                                    <div className="control">
                                        <input className={`input ${this.state.formError.length !== 0 ? 'is-danger':''}`} type="text" placeholder="Enter your home address" onChange={this.changeAddress} value={this.state.form_address}></input>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <input className="button is-primary" type="submit" value="Find My Grand Lakes MUD" />
                                    </div>
                                </div>  
                                { this.state.formError.length !== 0 ? (<div className="has-text-danger">{this.state.formError.map((error) => (
                                    <div key={error} >{error}</div>
                                )) }</div>): < ></ > }
                            </form>
                            </>
                        ) : (
                            <>
                                <div className="notification is-primary">{this.state.formSuccess}</div>
                                <h3 style={{fontWeight: 600}} className="is-title is-size-5">{this.state.formatted_address}</h3>
                                {this.state.district_name !== '' ? (
                                    <>
                                        <p style={{marginTop: '1rem'}}>Your District is {this.state.district_name}</p>
                                        <a className="button" href={`${this.state.district_name === "Grand Lakes MUD 1" ? "https://www.grandlakesmud1.com/" : ""}${this.state.district_name === "Grand Lakes MUD 2" ? "https://www.grandlakesmud2.com/" : ""}${this.state.district_name === "Grand Lakes MUD 4" ? "https://www.grandlakesmud4.com/" : ""}`}>View Website</a>
                                    </>
                                ) : (
                                    <>
                                        <p style={{marginTop: '1rem'}}>Your District was not found!</p>
                                        <button className="button is-primary" onClick={this.reset} >Try Again</button>
                                    </>
                                )}
                            </>
                        )}

                    </div>
                    <div className="window-footer">
                        &copy; 2021 Grand Lakes Municipal Utility Districts
                    </div>
                </div>
                <div className="center-message">
                    <div className="window-body content">
                        <p className="is-size-5">For questions regarding smart meters and the EyeOnWater app, please click below to view the information on the District Operator, Municipal District Services, website.</p>
                        <a href="https://www.mdswater.com/smartmeter/" className="button is-primary">SMART Meter Information</a>
                    </div>
                </div>
                <div className="center-message">
                    <div className="window-body content has-text-left">
                        <h2 className="has-text-centered">2024 Hurricane Preparedness</h2>
                        <p className="is-size-5">Hurricane Season officially begins June 1 and last through November 30. Unfortunately, this close to the Gulf of Mexico, it only takes one storm to change your life and community. Tropical cyclones are among nature’s most powerful and destructive phenomena.</p>
                        <p className="is-size-5">If you live in an area prone to tropical cyclones, you need to be prepared. <strong>Use the tips below provided from the National Weather Service to ensure you and your family are prepared for Hurricane Season</strong>.</p>
                        <h3>Determine your risk</h3>
                        <p className="is-size-5">The threats from hurricanes to you and your family can vary widely depending on where you live. It’s not just those along the coast that can experience significant, life-threatening impacts. Evaluate what you need to do to protect your home and family NOW, before the first storm of the season even forms in the Gulf. <a href="https://www.noaa.gov/hurricane-prep">noaa.gov/hurricane-prep</a>.</p>
                        <h3>Develop an evacuation plan</h3>
                        <p className="is-size-5">Make sure you have a hurricane evacuation plan. The first thing you need to do is find out if you live in a storm surge hurricane evacuation zone or if you’re in a home that would be unsafe during a hurricane. If you are, figure out where you’d go and how you’d get there if told to evacuate. You do not need to travel hundreds of miles. Identify someone, perhaps a friend or relative who doesn’t live in an evacuation zone or unsafe home, and coordinate with them to use their home as your evacuation destination. Be sure to account for your pets, as most local shelters do not permit them. Put the plan in writing for you and those you care about. <a href="https://www.noaa.gov/hurricane-prep">noaa.gov/hurricane-prep</a></p>
                        <h3>Assemble disaster supplies</h3>
                        <p className="is-size-5">Just having enough supplies to make it through a hurricane isn’t enough. You need plenty to make it through what could be a LONG recovery period too. Water and electricity could be out for a week or more. Have enough non-perishable food, water and medicine to last each person in your family for a MINIMUM of three days. Also make sure you have extra cash, a battery-powered radio, flashlights, and a portable crank or solar powered USB charger to charge your cell phone. <a href="https://www.ready.gov/build-a-kit">ready.gov/kit</a></p>
                        <p className="is-size-5"><strong>If possible, your kit should include the following:</strong><br /> <ul> <li>Seven-day supply of non-perishable (canned) food</li> <li>One gallon of drinking water, per day, for each member of your family, for seven days</li> <li>Cash – if power fails, ATMs may not work</li> <li>Portable battery or hand crank radio with the weather band</li> <li>Flashlights for each member of the family and extra batteries</li> <li>Personal items – medications, extra clothes, hygiene items</li> <li>Seven-day supply of food, water, and medication for your pets</li> </ul></p>
                        <h3>Get an insurance checkup</h3>
                        <p className="is-size-5">Call your insurance company or agent and ask for an insurance checkup to make sure you have enough homeowners insurance to repair or even replace your home and remember, standard homeowners insurance doesn’t cover flooding. Whether you’re a homeowner or renter, you’ll need a separate policy for flooding. Act now as flood insurance requires a 30-day waiting period. <a href="https://www.floodsmart.gov/">floodsmart.gov</a></p>
                        <h3>Strengthen your home</h3>
                        <p className="is-size-5">There's a lot you can do around your home to help protect it from hurricane winds. Take action now before hurricane season hits full-swing. Have the proper plywood, steel or aluminum panels to board up the windows and doors. Remember, the garage door is the most vulnerable part of the home, so it must be able to withstand high winds. <a href="https://flash.org/peril/hurricane/">flash.org/peril/hurricane/</a></p>
                        <h3>Help your neighbor</h3>
                        <p className="is-size-5">Many people rely on their neighbors after a disaster, but there are also many ways you can help your neighbors before a hurricane approaches. Learn about all the different actions your community can take to prepare and recover from the hazards associated with hurricanes. Check with neighbors, <a href="https://www.ready.gov/older-adults">senior adults</a>, or <a href="https://www.ready.gov/disability">those who may need additional help</a> securing hurricane plans to see how you can be of assistance to others.</p>
                        <h3>Complete a written plan</h3>
                        <p className="is-size-5">The time to prepare for a hurricane is NOW, as the season begins. Once you’re under pressure, having a written plan will take the guesswork out of what you need to do to protect you and your family. Know where you will ride out the storm and get your supplies now. You don’t want to be standing in long lines when a Hurricane Watch is issued. Those supplies that you need will probably be sold out by the time you reach the front of the line. Being prepared now will mean the difference between your being a hurricane victim and a hurricane survivor. <a href="https://ready.gov/plan">ready.gov/plan</a></p>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default HomePage

export const homePageQuery = graphql`
    query rootPage {
        image1: file(relativePath: {eq: "DJI_0012.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        image2: file(relativePath: {eq: "DSC_1054.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        image3: file(relativePath: {eq: "DSC_4444.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        image4: file(relativePath: {eq: "DSC_4434.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        image5: file(relativePath: {eq: "DSC_4462.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        image6: file(relativePath: {eq: "YUN_0004.JPG"}) {
            childImageSharp {
                fluid(fit: COVER, maxWidth: 1024) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`


